import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Flex, Box} from '@rebass/grid'
import includes from 'lodash/includes'
import {compose} from 'recompose'
import toLower from 'lodash/toLower'
import {createStructuredSelector} from 'reselect'
import {withTranslation} from 'react-i18next'
import {H2, Input, Image, Text, Button, Searchable} from 'components'
import {requestGroups2} from 'user/signup'
import {GroupList} from 'groups/components'
import Context from '../Context'
import steps from '../steps'
import { StyledCheckbox } from 'components'
import LinkGroupModal from './LinkGroupModal'
import { useState } from 'react'
import {withRouterParams} from 'lib/hoc'

const UserOverview = ({t, createdUser, requestGroups2})=>  {
  const [bulk, setBulk] = useState(false)
  const [showLinkGroups, setShowLinkGroups] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [dataLinkGroups, setDataForShowLinkGroups] = useState([])

  const newUser = createdUser 
  const children = createdUser.children

  if (newUser != null) {
    return (
      <Context.Consumer>
        {({club: {name, imageUrl, groups}, club, clubToken, setStep}) => {

          return (
            <Flex flexDirection="column" alignItems="center">
              <Box mb={3}>
                <H2>{t('Anmod grupper')}</H2>
              </Box>
              <Box mb={2}>
                <H2>
                    <strong>{t('Personer')}</strong>
                </H2>
              </Box>
              {
                children && 
                    <Box width={1} style={{textAlign: "center"}}>
                        { children && 
                            children.map((child) => 
                              <Box style={{borderBottom: "1px solid #BFC8D3"}}>
                                  <Flex justifyContent="space-between" alignItems="center" width={1} my={3} pb={2}>
                                    <Text style={{fontSize: "1.2rem"}} strong>{child.firstName} {child.surname}</Text>
                                    {groups.length >= 5 && <Button small onClick={()=> {
                                        setShowLinkGroups(true)
                                        setSelectedUser(child);

                                    }}>{t("Se grupper")}</Button> }
                                </Flex>
                                {
                                  groups.length < 5 && 
                                  groups.slice(0, 5).map((group) => {
                                    const isGroupInArray = child.groups != null ? child.groups.some(g => g.id === group.id) : false;
                      
                                    return <Flex justifyContent="space-between" width={1} >
                                      <Text mb={2}>{group.title}</Text>
                                      <StyledCheckbox checked={isGroupInArray} 
                                        onChange={({target: {checked}}) => {

                                          if (checked) {        
                                            if (child.groups == null) {
                                              child.groups = []
                                            }

                                            var newSelectedGroups = child.groups.filter(g => g.id != group.id);
                                            newSelectedGroups.push(group);
                                            child.groups = newSelectedGroups
                                          } else {
                                            var newSelectedGroups = child.groups.filter(g => g.id != group.id);
                                            child.groups = newSelectedGroups
                                          }
                                        
                                          setBulk(!bulk)
                                        }} />
                                    </Flex>

                                  })
                                }
                              
                              </Box>
                            )}
                    </Box>
              }

              <Flex justifyContent="space-between" alignItems="center" width={1} my={3} pb={2}>
                <Text style={{fontSize: "1.2rem"}} strong>{newUser.firstName} {newUser.surname}</Text>
                { groups.length >= 5 && <Button small onClick={()=> {
                  setShowLinkGroups(true)
                  setSelectedUser(newUser);
                }}>{t("Se grupper")}</Button> }
              </Flex>
              <Box style={{borderBottom: "1px solid #BFC8D3"}} width={1}>
                  {
                    groups.length < 5 && 
                    groups.slice(0, 5).map((group) => {
                      const isGroupInArray = newUser.groups != null ? newUser.groups.some(g => g.id === group.id) : false;
                      
                      return <Flex justifyContent="space-between" width={1}>
                        <Text mb={2}>{group.title}</Text>
                        <StyledCheckbox checked={isGroupInArray} onChange={({target: {checked}}) => {
                          if (checked) {             
                            if (newUser.groups == null) {
                              newUser.groups = []
                            }

                            var newSelectedGroups = newUser.groups.filter(g => g.id != group.id);
                            newSelectedGroups.push(group);
                            newUser.groups = newSelectedGroups
                          } else {
                            var newSelectedGroups = newUser.groups.filter(g => g.id != group.id);
                            newUser.groups = newSelectedGroups
                          }
                          setBulk(!bulk)
                        }} />
                      </Flex>

                    })
                  }

              </Box>
              {/* {
                parents && 
                <Box width={1} style={{textAlign: "center"}}>
                        <H2>Forældre</H2>
                        {
                            parents.map((parent) => 
                                <Flex justifyContent="space-between" alignItems="center" width={1} my={3} pb={2} style={{borderBottom: "1px solid #BFC8D3"}}>
                                    <Text style={{fontSize: "1.2rem"}} strong>{parent.name}</Text>
                                    <Button small onClick={()=>setStep(steps.GROUPS) }>{t("Se grupper")}</Button>
                                </Flex>
                            )
                        }
                    </Box>
              } */}
              {
                showLinkGroups && 
                  <LinkGroupModal t={t} groups={groups} selectedUser={selectedUser} value={bulk}
                    toggleGroup={(selectedGroups, user) => {
                      user.groups = selectedGroups;
                      setSelectedUser(null)
                    }}
                    hide={()=>setShowLinkGroups(false)}/>
              }

              <Box width={250} mt={3}>
                <Button
                  block
                  disabled={!(createdUser.groups != null && createdUser.groups.length > 0)}
                  primary
                  onClick={() => {

                    var groupAddingPromises = []
                    groupAddingPromises.push(new Promise((resolve, reject) => 
                    
                      requestGroups2({groups: createdUser.groups, createdUser, childUser: null, clubToken, resolve, reject})
                    ).catch((error) => {
                      console.log(error)
                    }));

                    if (children != null) {
                      for (let index = 0; index < children.length; index++) {
                        const child = children[index];

                        groupAddingPromises.push(new Promise((resolve, reject) =>
                          requestGroups2({groups: child.groups, createdUser, childUser: child, clubToken, resolve, reject})
                        ).catch((error) => {
                          console.log(error)
                        }));
                      }
                    }

                    setStep(steps.DOWNLOAD)
                  }}
                >
                  {t('Godkend')}
                </Button>
              </Box>
            </Flex>
          )
        }
      }
      </Context.Consumer>
    )
  } else {
    return (<></>)
  }
}

const enhancer = compose(
  connect(createStructuredSelector({}),
  {
    requestGroups2: requestGroups2.requested

  }
  ),
  withRouterParams, 
  withTranslation(), 
)

export default enhancer((props) => (
  <Context.Consumer>
    {(value) => <UserOverview {...props} {...value} />}
  </Context.Consumer>
))
